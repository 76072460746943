export const Colors = {
    mainDarkGrey: '#2F4454',
    mainPlum: '#2E151B',
    lightPlum: '#49212b',
    mainPink: '#DA7B93',
    linkBlue: 'blue',
    headerGrey: '#444649',
    extLinkGrey: '#696a6d',
    offWhiteBackground: '#f5f5f5',
    paragraphGrey: '#616161',
    errorRed: "#FF6961",
    mainBlue: "#4A77A1",
    lightBlue: "#ecf1f5",
    mainBeige: "#f1d4be"
}