import React from 'react'
import './App.css'
import styled from 'styled-components'

import { Colors } from './util/Colors'
import Puppy from './images/puppy.jpeg'

const App = () => {
	return (
		<Container>
			This site is under construction. For now, enjoy this puppy pic. xoxo,
			Shaundai
			<img src={Puppy} width='100%' />
		</Container>
	)
}

export default App

const Container = styled.div`
	background-color: ${Colors.lightBlue};
`
